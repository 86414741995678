import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import { SessionType } from '../../types'
import { useAxiosInstance } from '../../api/AxiosInstanceContext';
import { ReviewCasesContext } from './ReviewCasesContext';


interface ReviewSessionsContext {
    reviewSessions: SessionType[];
    reviewSessionsError: string | null;
    refreshReviewSessions: () => void;
    reviewSession: any;
    getReviewSession: (sessionId: number) => void;
    // saveReviewSession: ({ description, reviewers }: { description?: string | undefined; reviewers?: number[] | undefined; }) => void;
}

export const ReviewSessionsContext = createContext<ReviewSessionsContext>(undefined as any)

interface Props {
    children: React.ReactNode
}

export const ReviewSessionsProvider: React.FC<Props> = ({ children }) => {
    const axiosInstance = useAxiosInstance();
    const [reviewSessions, setReviewSessions] = useState([] as SessionType[]);
    const [reviewSessionsError, setReviewSessionsError] = useState<string | null>(null);
    const [reviewSession, setReviewSession] = useState<any>(null);
    const [reviewSessionError, setReviewSessionError] = useState<any>(null);
    const { reviewCases } = React.useContext(ReviewCasesContext);


    useEffect(() => {
        if (reviewCases) {
            const body = { cases_ids: reviewCases.map(c => c.id) }
            axiosInstance.post('/review_sessions', JSON.stringify(body))
                .then(({ data }) => {
                    setReviewSessions(data)
                })
                .catch((e) => setReviewSessionsError(e));
        }
    }, [reviewCases]);

    const refreshReviewSessions = () => {
        if (reviewCases) {
            const body = { cases_ids: reviewCases.map(c => c.id) }
            axiosInstance.post('/review_sessions', JSON.stringify(body))
                .then(({ data }) => {
                    setReviewSessions(data)
                })
                .catch((e) => setReviewSessionsError(e));
        }
    }

    const getReviewSession = async (sessionId: number) => {
        axiosInstance.get(`/review_session/?session_id=${sessionId}`)
            .then(({ data }) => setReviewSession(data))
            .catch((e) => setReviewSessionError(e));
    }

    const values = {
        reviewSessions: reviewSessions ? reviewSessions : [],
        reviewSessionsError,
        refreshReviewSessions,
        reviewSession,
        setReviewSessionError,
        getReviewSession,
    }

    return <ReviewSessionsContext.Provider value={values}>{children}</ReviewSessionsContext.Provider>
}
