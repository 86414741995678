import { FilterOptionType } from './types';

export const ProtocolLevels = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  PSYCHOLOGY: 'PSYCHOLOGY',
  OTHER: 'OTHER',
}

export const AreaStateTimeType = {
  POST_SESSION: 'POST_SESSION',
  PRE_SESSION: 'PRE_SESSION',
}

export const CaseStates = [
  'UNSUBMITTED',
  'SUBMITTED',
  'IN_REVIEW',
  'INFO_REQUESTED',
  'DENIED',
  'APPROVED',
  'ESCALATED',
  'ESCALATION_RESPONSE',
  'NONE'
];

export const editEnabledCaseStates = [
  'UNSUBMITTED',
  'SUBMITTED',
  'DENIED',
  'INFO_REQUESTED',
  'NONE'
];

export const displayEditEnabledCaseStates = editEnabledCaseStates.map((s) => s.toLowerCase().replace('_', ' '));

export const reviewCaseStates: FilterOptionType[] = [
  { id: 'UNSUBMITTED', name: 'unsubmitted' },
  { id: 'SUBMITTED', name: 'submitted' },
  { id: 'IN_REVIEW', name: 'in review' },
  { id: 'INFO_REQUESTED', name: 'info requested' },
  { id: 'DENIED', name: 'denied' },
  { id: 'APPROVED', name: 'approved' },
  { id: 'ESCALATED', name: 'escalated' },
  { id: 'ESCALATION_RESPONSE', name: 'escalation response' },
  { id: 'NONE', name: null }
];

// STORED VALUES
export const stored = {
  reviewCasesFilters: 'reviewCasesFilters',
}

export const CoursesIds = {
  APH_ID: 1,
  CPH_ID: 2
}